import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";

// import Home from "../Pages/Home";
// import About from "../Pages/About";
// import Markets from "../Pages/Markets";
// import Services from "../Pages/Services";
// import News from "../Pages/News";
// import NewsDetail from "../Pages/NewsDetail";
// import Careers from "../Pages/Careers";
// import Contact from "../Pages/Contact";
// import CareersDetail from "../Pages/CareersDetail";
// import Demo from "../Sections/Demo";

const Home = lazy(() => import("../Pages/Home"));
const About = lazy(() => import("../Pages/About"));
const Markets = lazy(() => import("../Pages/Markets"));
const Services = lazy(() => import("../Pages/Services"));
const Companies = lazy(() => import("../Pages/Companies"));
const News = lazy(() => import("../Pages/News"));
const NewsDetail = lazy(() => import("../Pages/NewsDetail"));
const Careers = lazy(() => import("../Pages/Careers"));
const Contact = lazy(() => import("../Pages/Contact"));
const CareersDetail = lazy(() => import("../Pages/CareersDetail"));
// const Demo = lazy(() => import("../Sections/Demo"));

const MainRoutes = () => {
  return (
    <Suspense>
      <Routes>
        <Route path="*" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/companies" element={<Companies />} />
        <Route path="/markets" element={<Markets />} />
        <Route path="/news" element={<News />} />
        <Route path="/news/:id" element={<NewsDetail />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/careers/:id" element={<CareersDetail />} />
        <Route path="/contact" element={<Contact />} />
        {/* <Route path="/demo" element={<Demo />} /> */}
      </Routes>
    </Suspense>
  );
};

export default MainRoutes;
