import React, { useState, lazy, useFallback } from "react";

import { NavLink } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

import Hamburger from "hamburger-react";
import { useTranslation } from "react-i18next";

// import LanguageSelector from "./LanguageSelector";
import Logo from "../../assets/logos/logo_white.svg";

const Header = () => {
  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(false);

  return (
    <Navbar expanded={isOpen} fixed="top" bg="#001f5f" expand="lg">
      <Container>
        <Navbar.Brand as={NavLink} to="/">
          <img src={Logo} alt="Metis group" className="metis-logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-nav">
          <Hamburger toggled={isOpen} toggle={setOpen} />
        </Navbar.Toggle>
        <Navbar.Collapse id="navbar-nav">
          <Nav className="mx-auto text-center">
            <NavLink to="/" end className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")} onClick={() => setOpen(false)}>
              {t("home_title")}
            </NavLink>
            <NavLink to="/about" className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")} onClick={() => setOpen(false)}>
              {t("about_title")}
            </NavLink>
            <NavLink to="/services" className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")} onClick={() => setOpen(false)}>
              {t("services_title")}
            </NavLink>
            <NavLink to="/companies" className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")} onClick={() => setOpen(false)}>
              {t("companies_title")}
            </NavLink>
            <NavLink to="/markets" className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")} onClick={() => setOpen(false)}>
              {t("markets_title")}
            </NavLink>
            <NavLink to="/news" className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")} onClick={() => setOpen(false)}>
              {t("news_title")}
            </NavLink>
            <NavLink to="/careers" className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")} onClick={() => setOpen(false)}>
              {t("careers_title")}
            </NavLink>
            <NavLink to="/contact" className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")} onClick={() => setOpen(false)}>
              {t("contact_title")}
            </NavLink>
            {/* <LanguageSelector></LanguageSelector> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
